<template>
  <div class="section__container">
    <div class="section__content" style="padding-top: 0px; padding-bottom: 0px;">
      <div class="home__container">
        <!-- Mi salud digital, KPIss -->
        <ChartsBanner
          v-if="checkModuleActive('HM')"
        />
        <!-- Vigilancia de la salud -->
        <div
          class="home__item home__item--main home__item--health-monitoring"
          :class="{'home__item--disabled' : !checkModuleActive('HM'),
                   'home__item--health-monitoring--no-active' : !checkModuleActive('HM') && checkModuleActive('TP')}"
          @click="checkModuleActive('HM') === true ? goToHealthMonitoring(0) : null"
        >
          <div class="home__item__title-container">
            <span class="home__item__icon icon icon-healthcare" />
            <span class="home__item__title">{{ $t('menu.healthMonitoring.title') }}</span>
          </div>
          <div class="home__item__link-container">
            <span class="home__item__link-container__link">{{ $t('home.checkLastMedicalExamination') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
        <!-- Prevención técnica -->
        <div
          class="home__item home__item--main home__item--technical-prevention"
          :class="{'home__item--disabled' : !checkModuleActive('TP'),
                   'home__item--technical-prevention--unique-module' : !checkModuleActive('HM') && checkModuleActive('TP')}"
          @click="checkModuleActive('TP') === true ? routePush('/technical-prevention') : null"
        >
          <div class="home__item__title-container">
            <span class="home__item__icon icon icon-helmet-home" />
            <span class="home__item__title">{{ $t('menu.technicalPrevention.title') }}</span>
          </div>
          <div class="home__item__link-container">
            <span class="home__item__link-container__link">{{ $t('home.checkYourRiskEvaluation') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
        <!-- Pedir cita -->
        <!-- TODO: Se incluyen los datos de la cita en nextAppoinment -->
        <div
          class="home__item home__item--request-appoinment"
          :class="{'home__item--request-appoinment--no-has-analyticals' : !haveAnalyticals ,
                   'home__item--disabled' : !checkModuleActive('AS') || !checkModuleActive('HM'),
                   'home__item--request-appoinment--inactive' : false}"
          @click="checkModuleActive('AS') === true && !nextAppoinment ? goToRequestAppointment() : nextAppoinment ? goToLastAppointment() : null"
        >
          <span class="home__item__icon icon icon-ticket" />
          <span
            v-if="!nextAppoinment && checkModuleActive('HM')"
            class="home__item__title"
          >{{ $t('menu.myAppointments.requestAppointments') }}</span>
          <span
            v-if="!checkModuleActive('HM')"
            class="home__item__title home__item__title--sm"
          >{{ $t('home.bookYourAppoinment') }}</span>
          <span
            v-if="nextAppoinment && checkModuleActive('HM')"
            class="home__item__title"
          >{{ nextAppoinment.date | dateAppoinment }}</span>
          <div class="home__item__link-container">
            <span
              v-if="!nextAppoinment"
              class="home__item__link-container__link"
            >{{ $t('home.requestAppoinment') }}</span>
            <span
              v-if="nextAppoinment"
              class="home__item__link-container__link"
            >{{ $t('home.yourAppoinment') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
        <!-- Analíticas -->
        <div
          class="home__item home__item--analyticals"
          :class="{'home__item--hidden' : !haveAnalyticals || !checkModuleActive('HM') }"
          @click="goToHealthMonitoring(2)"
        >
          <span class="home__item__icon icon icon-beakers" />
          <span class="home__item__title">{{ $t('menu.healthMonitoring.analytical') }}</span>
          <div class="home__item__link-container">
            <span class="home__item__link-container__link">{{ $t('home.lastAnalyticResults') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
        <!-- Guías saludables -->
        <div
          class="home__item home__item--healthy-guides"
          :class="{'home__item--disabled' : !checkModuleActive('HM')}"
          :to="'/login'"
          @click="checkModuleActive('HM') ? goToHealthMonitoring(5) : null"
        >
          <span class="home__item__title">{{ $t('menu.healthMonitoring.healthyGuides') }}</span>
          <div class="home__item__link-container">
            <span class="home__item__link-container__link">{{ $t('home.occupationalHealthRecommendations') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
        <!-- Calendario formativo -->
        <div
          class="home__item home__item--open-classroom-calendar"
          :class="{'home__item--disabled' : !checkModuleActive('TP'),
                   'home__item--open-classroom-calendar--unique-module' : !checkModuleActive('HM') && checkModuleActive('TP')}"
          @click="checkModuleActive('TP') === true ? goToOpenClassroomCalendar() : null"
        >
          <span class="home__item__icon icon icon-calendar-large" />
          <span class="home__item__title">{{ $t('menu.technicalPrevention.openClassroomCalendar') }}</span>
          <div class="home__item__link-container">
            <span class="home__item__link-container__link">{{ $t('home.checkAvailableCourses') }}</span>
            <span class="home__item__link-container__icon icon icon-next" />
          </div>
          <div class="home__item__not-available">
            <span class="home__item__not-available__icon icon icon-lock-fill-s" />
            <span class="home__item__not-available__text">{{ $t('home.notAvailableSection') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as _modules from '../_helpers/modules.helper.js'
import ChartsBanner from '@/components/digital-health/ChartsBanner.vue'

export default {
  name: 'Home',
  components: { ChartsBanner },
  data () {
    return {
      modulesActive: null,
      menu: [],
      breadcrumbLinks: [
        { label: this.$t('menu.home'), path: undefined }
      ],
      // TODO: Para activar / Desactivar módulos cambiar la propiedad active
      modules: [
        { code: 'HM', active: true },
        { code: 'TP', active: true },
        { code: 'AS', active: false },
        { code: 'TPS', active: true },
        { code: 'Telemedicina', active: false }
      ],
      // TODO: Cambia el contenido de Solicitar cita: nextAppoinment null = sin cita;
      nextAppoinment: null,
      // nextAppoinment: { date: '2022-02-13T23:45:00' },
      // TODO: haveAnalyticals hace que se expanda o no el bloque de Solicitar cita
      haveAnalyticals: true
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getAppointments', 'getModules', 'getPath', 'getParamSection'])
  },
  mounted () {
    window.scrollTo(0, 0)
    // console.log(_modules.getClientModules())
    this.menu = [...this.getMenu]
    this.modules[0].active = _modules.hasVs()
    this.modules[1].active = _modules.hasPt()
    // this.modules[2].active = !_modules.appointmentsDisabled()
    this.modules[2].active = this.getAppointmentsEnabled()
    console.log('has TPS', _modules.hasTps())
    this.modules[3].active = _modules.hasTps()
    this.modules[4].active = _modules.hasTelemedicina()

    this.$store.commit('setBreadcrumbLinks', this.breadcrumbLinks)
    this.menu[0].selected = true
    this.menu[0].selected = true
    this.$store.commit('setMenu', this.menu)
    // console.log(this.$i18n.locale)
  },
  methods: {
    getAppointmentsEnabled () {
      const a = _modules.appointmentsDisabled()
      console.log(a)
      if (!a.hasVs) {
        return false
      }
      // has active appointment
      if (a.lastAppointment) {
        this.nextAppoinment = a.lastAppointment
        return true
      }
      if (a.checkExpiredDate) {
        // console.log(a.appointments)
        // console.log(a)
        return false
      }
      return true
    },
    checkModuleActive: function (code) {
      return this.modules.find(module => module.code === code).active
    },
    routePush: function (path) {
      this.$router.push(path)
    },
    goToOpenClassroomCalendar: function () {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.menu[3].selected = true
      this.menu[3].opened = true
      this.menu[3].items[3].selected = true
      this.$store.commit('setParamSection', this.menu[3].items[3])
      this.$store.commit('setMenu', this.menu)
      if (this.getPath !== '/technical-prevention') {
        this.$router.push({ path: this.menu[3].path })
      }
    },
    goToRequestAppointment: function () {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.menu[4].selected = true
      this.menu[4].opened = true
      this.menu[4].items[1].selected = true
      this.$store.commit('setParamSection', this.menu[4].items[1])
      this.$store.commit('setMenu', this.menu)
      if (this.getPath !== '/my-appoinments') {
        this.$router.push({ path: this.menu[4].path })
      }
    },
    goToLastAppointment: function () {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.menu[4].selected = true
      this.menu[4].opened = true
      this.menu[4].items[0].selected = true
      this.$store.commit('setParamSection', this.menu[4].items[0])
      this.$store.commit('setMenu', this.menu)
      if (this.getPath !== '/my-appoinments') {
        this.$router.push({ path: this.menu[4].path })
      }
    },
    goToHealthMonitoring: function (index) {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.menu[2].selected = true
      this.menu[2].opened = true
      this.menu[2].items[index].selected = true
      this.$store.commit('setParamSection', this.menu[2].items[index])
      this.$store.commit('setMenu', this.menu)
      if (this.getPath !== '/health-monitoring') {
        this.$router.push({ path: this.menu[2].path })
      }
    }
  },
  // eslint-disable-next-line
  filters: {
    dateAppoinment: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMM  HH:mm')
    }
  }
}
</script>
