<template>
  <div class="the-header">
    <div class="the-header__container">
      <div class="the-header__row the-header__row--first">
      <div class="the-header__logo">
        <img src="../assets/logo-vitaly-nuevo.png" alt="">
      </div>
      <div class="the-header__right-content">
        <button class="button-download-tps" @click="openTPSModal()"><span class="label-download-tps">{{ $t('header.links.tpsalud')}}</span></button>
        <div class="the-header__secondary-menu margin-right-40" v-if="getCurrentDevice > DEVICES.TABLET">
          <span class="the-header__secondary-menu__item" @click="showFAQSModal()">{{ $t('header.links.faq') }}</span>
          <a href="https://www.preving.com/te-llamamos/" target="_blank" class="the-header__secondary-menu__item">{{ $t('header.links.contact') }}</a>
          <a href="https://www.preving.com/blog/" target="_blank" class="the-header__secondary-menu__item">{{ $t('header.links.blog') }}</a>
        </div>
        <Avatar />
      </div>
    </div>
    <div class="the-header__row the-header__row--second">
      <div class="the-header__menu">
          <div v-for="(item, index) in getMenucomponents" :key="index" class="the-header__menu__item" :class="{'the-header__menu__item--active' : item.opened || item.selected}" @click="navigate(item.path)" >
              <div class="the-header__menu__item__content">
                <span class="the-header__menu__item__icon icon" :class="getMenuIcon(item)"></span>
              <span class="the-header__menu__item__label">{{item.label}}</span>
              </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Avatar from '@/components/Avatar.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'TheHeader',
  components: { Avatar },
  props: ['currentUser'],
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES
    }
  },
  mounted () {
    this.getMenu.slice(0, 6).forEach(element => {
      console.log(element.label)
    })
    console.log(this.getMenu.slice(0, 3))
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getCurrentDevice', 'getMenu']),
    isExactActive () {
      return this.$router.path
    },
    getMenucomponents () {
      if (this.getCurrentDevice === this.DEVICES.MOBILE) {
        return this.getMenu.slice(0, 5)
      } else {
        return this.getMenu.slice(0, 6)
      }
    }
  },
  methods: {
    showFAQSModal () {
      this.$store.commit('setFAQSModalVisible', true)
    },
    getMenuIcon (item) {
      return item.path !== this.$router.history.current.path ? item.icon : item.icon + '-on'
    },
    navigate (path) {
      this.$store.commit('setParamSection', null)
      this.$router.push(path)
    },
    openTPSModal () {
      this.$store.commit('setTPSModalVisible', true)
    }
  }
}
</script>
<style scoped>
.button-download-tps {
  background-color: var(--accent-light-color);
  border-radius: 2px;
  height: 33px;
  width: 140px;
  margin: 0px 20px;
}
.label-download-tps {
  color: var(--black-color);
  font-size: 13px;
  font-weight: 600;
  line-height: 20.8px;
}
</style>
