<template>
  <v-app>
    <div v-if="getCurrentDevice === DEVICES.MOBILE && isDownloadHeaderVisible" class="download-header">
      <span v-html="$t('header.discover')"></span>
      <button class="button button--dark-orange" @click="openTPSModal()">{{ $t('header.download') }}</button>
    </div>
    <div
      v-if="isMobileMenuIconVisible"
      class="mobile-header"
    >
      <img
        src="@/assets/logo-vitaly-nuevo.png"
        v-if="isLogoHomeVisible"
      >
      <span
        v-if="getParamSection"
        class="mobile-header__title"
      >{{ getParamSection.label }}</span>
      <span
        class="mobile-menu__icon-menu icon icon-menu"
        @click="showMobileMenu()"
      />
    </div>
    <FAQS v-if="getIsFAQSModalVisible" />
    <MobileMenu :is-visible="getIsMobileMenuVisible" />
    <TheHeader v-if="isHeaderVisible" />
    <!-- <Breadcrumb :data="getBreadcrumbLinks" v-if="isBreadcrumbVisible" /> -->
    <TPSModal v-if="getIsTPSModalVisible" />
    <router-view />
    <CookiesBar
      :visible="getCookiesBarVisible"
      @openCookiesText="openCookies()"
    /><!-- isCookiesBarVisible -->
    <TheFooter v-if="isFooterVisible" />
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import commonService from '@/services/commonService.js'
import TheHeader from '@/components/TheHeader.vue'
import CookiesBar from '@/components/commons/CookiesBar.vue'
import TheFooter from '@/components/TheFooter.vue'
import MobileMenu from '@/components/commons/MobileMenu.vue'
import FAQS from '@/components/commons/FAQS.vue'
import TPSModal from '@/components/tps/TPSModal.vue'

export default {
  name: 'App',
  components: {
    TheHeader, TheFooter, MobileMenu, FAQS, CookiesBar, TPSModal
  },
  data () {
    return {
      // isMobileMenuIconVisible: true,
      MEDIA_QUERIES: Vue.CONSTANTS.MEDIA_QUERIES,
      DEVICES: Vue.CONSTANTS.DEVICES
    }
  },
  computed: {
    ...mapGetters(['getCookiesBarVisible', 'getCurrentUser', 'getCurrentDevice', 'getBreadcrumbLinks', 'getLanguages', 'getIsMobileMenuVisible', 'getMenu', 'getParamSection', 'getIsFAQSModalVisible', 'getRecognitionDataStatus', 'getIsTPSModalVisible']),
    isLogoVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    },
    isLogoHomeVisible: function () {
      return this.$route.name === 'Home'
    },
    isHeaderVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    },
    isBreadcrumbVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    },
    isFooterVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    },
    isMobileMenuIconVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    },
    isCookiesBarVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'MaintenanceMode'
    },
    isDownloadHeaderVisible: function () {
      return this.$route.name !== 'RestorePassword' && this.$route.name !== 'Login' && this.$route.name !== 'ResetPassword' && this.$route.name !== 'ConsentForm' && this.$route.name !== 'ChartTest' && this.$route.name !== 'MaintenanceMode'
    }
  },
  watch: {
    getLanguages: function (newVal, oldVal) {
      // // console.log()
    },
    $route (to, from) {
      // first time to call api
      if (to.name === 'Home' && from.name === 'Login') this.$store.dispatch('fetchApi')
      // if (to.name === 'Home' && from.name === 'Login' && this.getCurrentDevice === 1) this.$router.push('/general-info')
      if (to.name === 'Login') {
        this.$store.commit('reset')
        this.$store.commit('resetKpiConfig')
        this.$store.dispatch('resetAllState')
      }
      this.closeAllMenus()
      const path = to.path
      this.$store.commit('setPath', path)
      switch (path) {
        case '/':
          this.menu[0].selected = true
          this.$store.commit('setParamSection', null)
          break
        case '/general-info':
          this.menu[1].opened = true
          break
        default:
          break
      }
      this.$store.commit('setMenu', this.menu)
    }
  },
  mounted () {
    console.log('route', this.$route.name)

    // console.log('App.vue Mounted', window.VUE_APP_MAINTENANCE);
    if (window.VUE_APP_MAINTENANCE === true) { // process.env.VUE_APP_MAINTENANCE
      this.$router.push('/maintenance-mode')
    }
    this.menu = [...this.getMenu]
    // commonService.loadLanguages()
    // this.$store.commit('setLanguages', this.languages)
    // console.log()
    this.onResize()
    console.log('this.getCurrentDevice', this.getCurrentDevice)
    this.$store.dispatch('fetchTerms')

    this.$store.commit('setMenu', this.menu)
  },
  created () {
    window.addEventListener('resize', this.onResize)
    // console.warn(this.$router.currentRoute.name)
  },
  methods: {
    onResize: function () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < this.MEDIA_QUERIES.TABLET) {
        this.$store.commit('setCurrentDevice', this.DEVICES.MOBILE)
      } else if (this.windowWidth > this.MEDIA_QUERIES.TABLET && this.windowWidth < this.MEDIA_QUERIES.DESKTOP) {
        this.$store.commit('setCurrentDevice', this.DEVICES.TABLET)
      } else {
        this.$store.commit('setCurrentDevice', this.DEVICES.DESKTOP)
      }
    },
    showMobileMenu: function () {
      this.$store.commit('setIsMobileMenuVisible', true)
    },
    closeAllMenus () {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.$store.commit('setMenu', this.menu)
    },
    redirectGeneralInfo () {
      if (this.$router.currentRoute.path === '/') {
        this.$router.push('/general-info')
      }
    },
    openTPSModal () {
      this.$store.commit('setTPSModalVisible', true)
    }
  }
}
</script>

<style scoped>

  .download-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    background-color: var(--black-5-color);
    padding: 10px;
  }

  .download-header .button{
    font-weight: 700;
    height: 32px;
    padding: 0px 15px;
  }
</style>
