<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <!-- <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" /> -->
    <UpDocModal :data="docModalData" :docTitle="docTitle" :title="docModalTitle" :isLoading="isLoading" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParamsFinal" :isResignationLoading="isResignationLoading" :isShareLoading="isShareLoading" v-if="isModalVisible" @onSendResignation="sendResignation($event)" @onSelectClient="selectClient($event)" @onClose="closeModal()" @onSendFile="sendEmailFile($event)"/>
    <h1 class="subsection__title">{{ $t('menu.healthMonitoring.medicalResignation') }}</h1>
    <p>
      {{ $t('medicalResignation.intro') }}
    </p>
    <div v-if="notSkeletonLoading" :class="!buttonDisabled && !simpleAdviceAlert ? 'd-block': 'd-flex'" style="justify-content: center; margin-bottom: 10px;">
      <Button
        v-if="buttonDisabled"
        :label="$t('menu.healthMonitoring.medicalResignation')"
        classes="button button--cta"
        :icon="'icon-add'"
        @click.native="openModal"
      />
      <SimpleAdvice v-else-if="simpleAdviceAlert" :text="$t('medicalResignation.resignationAvoid')" :bgColor="'yellow'" :showCloseIcon="false"/>
      <SimpleAdvice v-else :text="$t('medicalResignation.resignationComplete')" :bgColor="'grey'" :showCloseIcon="false"/>
    </div>
    <SkeletonCard v-if="!notSkeletonLoading"/><!-- <SkeletonCard v-if="skeletonIsVisible"/> -->
    <CardList
      v-if="resignations.length > 0 && notSkeletonLoading"
      :data="resignations"
      @onClickPreview="openPreview($event)"
      @onClickDownload="openDownload($event)"
      @onClickShareLink="openShareModal($event)"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import UpDocModal from '@/components/modals/UpDocModal.vue'
import Toast from '@/components/toasts/Toast.vue'
import SimpleAdvice from '@/components/ui/SimpleAdvice.vue'
import Modal from '@/components/modals/Modal.vue'
import Button from '@/components/ui/Button.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
// import NoResults from '@/components/commons/NoResults.vue'
import CardList from '@/components/commons/CardList.vue'
import resignationService from '@/services/resignationService.js'
import * as _files from '@/_helpers/files.helper.js'
import moment from 'moment'
export default {
  name: 'MedicalResignations',
  components: { UpDocModal, CardList, Modal, SkeletonCard, Toast, SimpleAdvice, Button },
  data () {
    return {
      resignationButton: true,
      resignationFinished: false,
      docModalTitle: null,
      countResignationLoop: 0,
      resignations: [],
      employmentHistoryData: [],
      workPlaceData: null,
      myWorkPlace: [],
      skeletonIsVisible: false,
      skeletonEmploymentVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      recognitionData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      isResignationLoading: false,
      isShareLoading: false,
      otherDocuments: [],
      isLoading: false,
      otherDocumentsData: [],
      docTitle: null,
      docModalData: null,
      shareModalParamsFinal: null,
      shareModalParamsEmail: {
        type: 5,
        title: this.$i18n.t('global.sendByEmail'),
        isLoading: false
      },
      shareModalParams: {
        type: 4,
        title: this.$i18n.t('menu.healthMonitoring.medicalResignation'),
        text: null,
        data: null,
        companies: null,
        size: 'md', /* sm, md, lg, full */
        isLoading: false
      },
      toastParams: null,
      documentList: [
        { id: 13458, type: 12, resignationDate: 'Fri Jun 10 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', fromDate: 'Fri Jun 15 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', untilDate: 'Fri Jun 20 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', examinationJob: 'Técnico de procesos', jobCompany: 'Sngular' },
        { id: 13459, type: 12, resignationDate: 'Fri Jun 11 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', fromDate: 'Fri Jun 16 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', untilDate: 'Fri Jun 21 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', examinationJob: 'Técnico de procesos', jobCompany: 'Sngular' },
        { id: 13460, type: 12, resignationDate: 'Fri Jun 12 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', fromDate: 'Fri Jun 17 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', untilDate: 'Fri Jun 23 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', examinationJob: 'Técnico de procesos', jobCompany: 'Sngular' },
        { id: 13461, type: 12, resignationDate: 'Fri Jun 13 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', fromDate: 'Fri Jun 18 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', untilDate: 'Fri Jun 24 2022 12:27:22 GMT+0200 (hora de verano de Europa central)', examinationJob: 'Técnico de procesos', jobCompany: 'Sngular' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getOtherDocumentsStatus', 'getOtherDocuments', 'getCurrentUser']),
    ...mapGetters(['getEmploymentHistoryStatus', 'getEmploymentHistory']),
    disableButton () {
      return this.skeletonIsVisible || this.skeletonEmploymentVisible
    },
    resignationAvoid () {
      return false
    },
    simpleAdviceAlert: function () {
      if (this.resignations.length > 0) {
        return false
      } else {
        return true
      }
    },
    buttonDisabled: function () {
      if (this.notSkeletonLoading) {
        return !this.checkButton()
      } else {
        return false
      }
    },
    notSkeletonLoading: function () {
      let boolEmploymentHistory = true
      let boolResignations = true
      switch (this.getEmploymentHistoryStatus) {
        case 'pending':
          boolEmploymentHistory = false
          break
        case 'success':
          boolEmploymentHistory = true
          break
        default:
          break
      }
      switch (this.resignationFinished) {
        case false:
          boolResignations = false
          break
        case true:
          boolResignations = true
          break
        default:
          break
      }
      return boolResignations && boolEmploymentHistory
    }
  },
  mounted () {
    this.getResignations()
    if (this.getEmploymentHistory !== null) {
      this.employmentHistoryData = this.getEmploymentHistory
      this.myWorkPlace = this.filterData()
    }
    if (this.getEmploymentHistoryStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getEmploymentHistoryStatus === 'pending') this.skeletonEmploymentVisible = true
  },
  methods: {
    openShareModal: function (event) {
      console.log('event', event)
      this.shareModalParamsEmail.file = event.id
      this.shareModalParamsEmail.resignationId = event.resignation.id
      this.shareModalParamsFinal = this.shareModalParamsEmail
      this.isModalVisible = true
    },

    openModal: function () {
      this.shareModalParams.data.forEach(element => {
        if (this.resignations.length > 0) {
          const x = this.resignations.find(x => x.laborRelation.id === element.laborRelation.id)
          if (x) console.log('openmodal log', x)
          if (x) element.laborRelation.disabled = true
          console.log(x)
        } else {
          if (element.periodicity === 0 || !element.laborRelation.resignationEnabled) element.laborRelation.disabled = true
        }
      })
      this.shareModalParams.companies.forEach(element => {
        // const x = this.shareModalParams.data.find(x => x.laborRelation.id === element.laborRelation.id && !x.laborRelation.disabled && x.laborRelation.resignationEnabled)
        const y = this.shareModalParams.data.find(x => x.laborRelation.resignationEnabled && x.periodicity !== 0 && x.laborRelation.clientId === element.laborRelation.clientId)
        console.log(element.laborRelation.id)
        console.log(y, 'y value')
        // console.log(x, 'x value')
        if (!y) element.disabled = true
      })
      console.log(this.shareModalParams.companies)
      this.shareModalParams.user = this.getCurrentUser
      this.shareModalParamsFinal = this.shareModalParams
      this.isModalVisible = true
    },
    checkButton: function () {
      console.log(this.shareModalParams)
      let count = 0
      this.shareModalParams.data.forEach(element => {
        const x = this.resignations.find(x => x.laborRelation.id === element.laborRelation.id)
        if (x || element.periodicity === 0 || !element.laborRelation.resignationEnabled) {
          count += 1
        }
      })
      // this.shareModalParams.companies.forEach(element => {
      //   const x = this.shareModalParams.data.find(x => x.laborRelation.id === element.laborRelation.id && !x.laborRelation.disabled && x.laborRelation.resignationEnabled)
      //   if (!x) count += 1
      // })
      console.log(this.shareModalParams.data.length, 'this.shareModalParams.data.length')
      console.log(count, 'count')
      console.log(this.shareModalParams.data.length - count <= 0)
      return (this.shareModalParams.data.length - count <= 0)
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    isSafari: function () {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
    openDownload: function (item) {
      const x = this.resignations.indexOf(item)
      console.log(x)
      console.log(item)
      if (x > -1) {
        this.resignations[x].isLoading = true
        console.log(this.resignations[x])
      }
      resignationService.getResignation(item.resignation.id)
        .then((response) => {
          // this.downloadFileFunction(response.data)
          // this.downloadFile2(response.data)
          console.log(response.data)
          _files.downloadPDF(response.data.file, response.data.fileName)
        })
        .catch(() => {
        })
        .finally(() => {
          this.resignations[x].isLoading = false
        })
    },
    downloadFile2: function (data) {
      const reader = new FileReader()
      const out = new Blob([data], { type: 'application/pdf' })
      reader.onload = function (e) {
        window.location.href = reader.result
      }
      reader.readAsDataURL(out)
    },
    downloadFileFunction: function (data) {
      const fileURL = 'data:application/pdf;base64,' + data
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.target = '_blank'
      fileLink.rel = 'external'
      fileLink.setAttribute('download', 'consent')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    openPreview: function (item) {
      this.docModalData = null
      console.log(item)
      this.isDocModalVisible = true
      this.isLoading = true
      this.docModalTitle = this.$i18n.t('menu.healthMonitoring.medicalResignation')
      resignationService.getResignation(item.resignation.id)
        .then((response) => {
          this.docModalData = response.data.file
          this.docTitle = response.data.fileName
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    // TO DO: temporal function to download resignation after accept
    downloadFile: function (file) {
      const fileURL = 'data:application/pdf;base64,' + file
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.target = '_blank'
      fileLink.rel = 'external'
      fileLink.setAttribute('download', 'consent')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    sendEmailFile: function (data) {
      this.isShareLoading = true
      resignationService.shareResignation(data.resignationId, data.fileName, data.toEmail)
        .then((response) => {
          console.log(response)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isToastVisible = true
          this.toastParams = {
            text: `El archivo se ha enviado correctamente a ${data.toName}`,
            type: 'success',
            time: 5000
          }
          this.isShareLoading = false
          setTimeout(() => {
            this.isToastVisible = false
          }, 7000)
          this.isModalVisible = false
        })
    },
    sendResignation: function (item) {
      this.isResignationLoading = true
      resignationService.uploadResignation(item, this.$i18n.locale)
        .then((response) => {
          console.log(response)
          // this.downloadFile(response.data)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isToastVisible = true
          this.toastParams = {
            text: this.$t('documentsUpload.uploadDocumentFinished'),
            type: 'success',
            time: 5000
          }
          this.isResignationLoading = false
          setTimeout(() => {
            this.isToastVisible = false
          }, 7000)
          this.isModalVisible = false
          this.resignationFinished = false
          this.resignationLoop()
        })
    },
    resignationLoop: function () {
      this.getResignationsLoop()
        .then((response) => {
          if (response.data.length === this.resignations.length && this.countResignationLoop < 5) {
            setTimeout(() => {
              this.countResignationLoop += 1
              this.resignationLoop()
            }, 2000)
          } else if (this.countResignationLoop === 5 || response.data.length !== this.resignations.length) {
            response.data.forEach(element => {
              element.type = 12
              element.isLoading = false
              element.resignation.fromDate = moment(element.validAt).subtract(element.periodicity, 'months').format('yyyy-MM-DD')
              console.log(moment(element.validAt).subtract(element.periodicity, 'months').format('yyyy-MM-DD'))
            })
            this.resignations = response.data

            this.resignationFinished = true
            this.skeletonIsVisible = false
            this.countResignationLoop = 0
          }
        })
        .catch(() => {
        })
        .finally(() => {
          // this.resignationFinished = true
          // this.skeletonIsVisible = false
        })
    },
    getResignationsLoop: function () {
      return resignationService.getResignations()
    },
    selectClient: function (clientId) {
      console.log(clientId)
      this.shareModalParams.data.forEach(element => {
        const x = this.resignations.find(x => x.laborRelation.id === element.laborRelation.id)
        console.log(x)
        if (x) element.laborRelation.disabled = true
        if (clientId === element.laborRelation.clientId) {
          element.selected = true
        } else {
          element.selected = false
        }
      })
      this.shareModalParams.companies.forEach(element => {
        const x = this.shareModalParams.data.find(x => x.laborRelation.id === element.laborRelation.id && !x.laborRelation.disabled && x.laborRelation.resignationEnabled)
        // const x = this.shareModalParams.data.find(x => x.laborRelation.id === element.laborRelation.id && !x.laborRelation.disabled)
        if (!x) element.disabled = false
      })
      console.log(this.shareModalParams.companies)
    },
    getResignations: function () {
      this.resignations = []
      this.skeletonIsVisible = true
      resignationService.getResignations()
        .then((response) => {
          console.log(response)
          response.data.forEach(element => {
            element.type = 12
            element.isLoading = false
            element.resignation.fromDate = moment(element.validAt).subtract(element.periodicity, 'months').format('yyyy-MM-DD')
          })
          this.resignations = response.data
        })
        .catch(() => {
        })
        .finally(() => {
          this.resignationFinished = true
          this.skeletonIsVisible = false
        })
    },
    filterData () {
      let arr = []
      if (this.employmentHistoryData.length > 0) {
        arr = this.employmentHistoryData.filter(workPlace => {
          if (workPlace.laborRelation.endDate === null) {
            workPlace.type = 8
            workPlace.selected = false
            workPlace.laborRelation.selected = false
            return workPlace
          }
        })
      }
      const arrayUniqueByKey = [...new Map(arr.map(item => [item.laborRelation.clientId, item])).values()]
      arrayUniqueByKey.forEach(element => {
        element.selected = false
      })
      console.log(arrayUniqueByKey)
      this.shareModalParams.data = arr
      this.shareModalParams.companies = arrayUniqueByKey
      return arr
    },
    consoleLog () {
      console.log('CLICK!')
    }
  },
  watch: {
    getEmploymentHistoryStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonEmploymentVisible = false
          this.employmentHistoryData = this.getEmploymentHistory
          this.myWorkPlace = this.filterData()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonEmploymentVisible = true
        }
      },
      deep: true
    },
    disableButton: {
      handler (newVal, oldVal) {
        if (oldVal === true && newVal === false) {
          this.checkButton()
        }
        if (oldVal === false && newVal === true) {
          this.resignationButton = true
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';

.my-digital-health {
  overflow: hidden;
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 13px;
    padding-left: 1px;
    padding-right: 1px;
    & h2 {
      flex-basis: 100%;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: $font-size-xl;
    }
  }
}

</style>
