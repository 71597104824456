<template>
  <div
    class="home__item home__item--main home__item--digital-health"
    v-if="visible"
  >
    <div class="head-container">
      <div class="home__item__title-container">
        <span class="home__item__icon icon icon-health-kpis" />
        <span class="home__item__title">{{ $t('menu.healthMonitoring.digitalHealth') }}</span>
      </div>
      <div class="home__item__link-container" style="cursor: pointer;" @click="goToMyHealth">
        <span class="home__item__link-container__link">{{ $t('home.heakthKpis') }}</span>
        <span class="home__item__link-container__icon icon icon-next" />
      </div>
    </div>
    <div
      class="grids-container"
      :style="`right:${rightMovement}px`"
      @mousedown="mouseDownHandler"
      v-if="isConfigLoading || !isDataLoaded"
    >
      <div class="chart-content">
        <SkeletonChartHome
          class="chart-banner"
          :width="200"
          :heigh="200"
        />
      </div>
      <div class="chart-content">
        <SkeletonChartHome
          class="chart-banner"
          :width="200"
          :heigh="200"
        />
      </div>
    </div>
    <!-- skeleton -->
    <div
      class="grids-container"
      :style="`right:${rightMovement}px`"
      @mousedown="mouseDownHandler"
      v-if="!isConfigLoading && isDataLoaded"
    >
      <div
        v-show="true"
        class="chart-content"
        v-for="(item, index) in kpis" :key="index"
      >
        <div class="hide-chart-icon" v-if="!item.isLoading">
          <span
            v-tooltip="{ content: $t('home.kpiHide') , delay: 0, offset: 5, visible: true }"
            class="glow-icon icon icon-delete"
            @click="chartsCount > 2 ? handleShowChart(item.description) : ''"
          />
        </div>
        <BarChartDoubleStack
          v-if="!item.isLoading"
          :barsData="filteredDataChart(item.description)"
          :title="getTitle(item.description)"
          :unitsY="measurement(item.description)"
          :subtitle="chartSubtitle(item.description)"
          :xLabels="filteredXlabelsChart(item.description)"
          :maxYlabels="maxYlabels(filteredDataChart(item.description))"
          :width="200"
          :heigh="200"
          :barColors="['rgb(255 255 255 / 60%)']"
          cssClasses="chart-banner"
        />
        <div class="chart-content" v-if="false">
          <SkeletonChartHome
            class="chart-banner"
            :width="200"
            :heigh="200"
          />
        </div>
      </div>
      <div class="chart-content" v-if="isItemLoading">
        <SkeletonChartHome
          class="chart-banner"
          :width="200"
          :heigh="200"
        />
      </div>
      <div
        v-if="chartsCount < 3 && !(chartsCount == 2 && isItemLoading)"
        class="chart-content"
      >
        <div
          v-if="chartsCount < 3"
          class="new-kpi-square"
          @click="handlerOptionsPanel"
        >
          <span class="icon icon-add" />
          <span class="new-kpi__text">{{ $t('home.newKpi') }}</span>
        </div>
      </div>
    </div>
    <!-- TODO v-if v-else cuadro o icono, los dos no. -->
    <div
      v-if="chartsCount > 2 && isDataLoaded"
      class="new-kpi-icon"
      @click="handlerOptionsPanel"
    >
      <span class="glow-icon icon icon-settings icon-16" />
    </div>
    <div
      :class="['new-kpi-options', showOptions ? 'opened' : '']"
    >
      <div class="new-kpi-options__head-container">
        <span
          class="new-kpi-options__head-container__icon icon icon-back icon-32"
          @click="handlerOptionsPanel"
        />
        <span class="new-kpi-options__head-container__title">{{ $t('home.kpiSelector') }}</span>
      </div>
      <div class="new-kpi-options__toggle-container">
        <Toggle
          v-tooltip="{ content: toggleTooltipText, delay: 0, offset: 5, visible: disableTooltips('weight')}"
          :isChecked="showCharts.weight"
          :title="getTitle('weight')"
          :disable="disableTooltips('weight')"
          :tooltip="'active'"
          @toggleEvent="handleShowChart('weight')"
        />
        <Toggle
          v-tooltip="{ content: toggleTooltipText, delay: 0, offset: 5, visible: disableTooltips('glucose')}"
          :isChecked="showCharts.glucose"
          :title="getTitle('glucose')"
          :disable="disableTooltips('glucose')"
          @toggleEvent="handleShowChart('glucose')"
        />
        <Toggle
          v-tooltip="{ content: toggleTooltipText, delay: 0, offset: 5, visible: disableTooltips('imc')}"
          :isChecked="showCharts.imc"
          :title="getTitle('imc')"
          :disable="disableTooltips('imc')"
          @toggleEvent="handleShowChart('imc')"
        />
        <Toggle
          v-tooltip="{ content: toggleTooltipText, delay: 0, offset: 5, visible: disableTooltips('cholesterolTotal')}"
          :isChecked="showCharts.cholesterolTotal"
          :title="getTitle('cholesterolTotal')"
          :disable="disableTooltips('cholesterolTotal')"
          @toggleEvent="handleShowChart('cholesterolTotal')"
        />
        <Toggle
          v-tooltip="{ content: toggleTooltipText, delay: 0, offset: 5, visible: disableTooltips('uricAcid')}"
          :isChecked="showCharts.uricAcid"
          :title="getTitle('uricAcid')"
          :disable="disableTooltips('uricAcid')"
          @toggleEvent="handleShowChart('uricAcid')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BarChartDoubleStack from '@/components/charts/BarChartDoubleStack.vue'
import Toggle from '@/components/ui/Toggle.vue'
import { mapActions, mapGetters } from 'vuex'
import SkeletonChartHome from '@/components/commons/SkeletonChartHome.vue'

export default {
  name: 'ChartsBanner',
  components: {
    BarChartDoubleStack, Toggle, SkeletonChartHome
  },
  data () {
    return {
      visible: true,
      isItemLoading: false,
      showOptions: false,
      showCharts: {
        weight: false,
        glucose: false,
        imc: false,
        bloodPress: false,
        cholesterolTotal: false,
        uricAcid: false
      },
      availableCharts: {
        weight: false,
        glucose: false,
        imc: false,
        cholesterolTotal: false,
        uricAcid: false
      },
      kpisCharts: [
        {
          description: 'weight',
          selected: false,
          data: null
        },
        {
          description: 'glucose',
          selected: false,
          data: null
        },
        {
          description: 'imc',
          selected: false,
          data: null
        },
        {
          description: 'bloodPress',
          selected: false,
          data: null
        },
        {
          description: 'cholesterolTotal',
          selected: false,
          data: null
        },
        {
          description: 'uricAcid',
          selected: false,
          data: null
        }
      ],
      numberOfDataToShow: 4,
      dataColors: [
        // se puede alternar el color de las barras
        // 'rgb(255 255 255 / 30%)',
        // 'rgb(255 255 255 / 40%)',
        // 'rgb(255 255 255 / 50%)',
        'rgb(255 255 255 / 60%)'
      ],
      chartDataBase: {
        title: 'Peso',
        subtitle: null, // se puede poner un texto o un dato que quetamos resaltar
        description: null,
        dataUnitsX: 'Años',
        dataUnitsY: 'Kg',
        minValue: null,
        maxValue: null,
        stepY: 10,
        data: {
          // la key de estos objetos conforman el eje X
          2015: 76.3,
          2016: 79.7,
          2017: 78.5,
          2018: 76.8,
          2019: 83.7,
          2020: null,
          2021: 84.1,
          2022: 81.5
        }
      },
      startMouse: 0,
      rightMovement: 0,
      startMovement: false,
      windowSize: null,
      maxMovement: null,
      isWeightLoaded: null,
      isImcLoaded: null,
      isGlucoseLoaded: null,
      isCholesterolTotalLoaded: null,
      isUricAcidLoaded: null
    }
  },
  computed: {
    ...mapGetters(['getKpiConfig', 'getKpiConfigLoaded', 'filteredDataWeight', 'filteredDataCholesterolTotal', 'filteredDataGlucose', 'filteredDataImc', 'filteredDataUricAcid']),
    ...mapGetters(['outstandingDataWeight', 'outstandingDataImc', 'outstandingDataGlucose', 'outstandingDataCholesterolTotal', 'outstandingDataUricAcid']),
    ...mapGetters(['getWeightState', 'getImcState', 'getGlucoseState', 'getCholesterolTotalState', 'getUricAcidState']),
    ...mapGetters(['filteredXlabelsWeight', 'filteredXlabelsGlucose', 'filteredXlabelsImc', 'filteredXlabelsCholesterolTotal', 'filteredXlabelsUricAcid']),
    kpis: {
      get () {
        console.log(this.getKpiConfig)
        return this.getKpiConfig
      }
    },
    isConfigLoading () {
      return !this.$store.state.kpiConfigStore.isLoaded
    },
    isDataLoaded () {
      return this.isWeightLoaded && this.isImcLoaded && this.isGlucoseLoaded && this.isCholesterolTotalLoaded && this.isUricAcidLoaded
    },
    chartsCount () {
      /*
      const arrayValues = Object.values(this.showCharts)
      const countTrue = arrayValues.filter(element => element === true).length
      return countTrue
      */
      return this.getKpiConfig.length
    },
    toggleTooltipText () {
      const tooltipAdvices = {
        2: this.$t('home.kpiMin'),
        3: this.$t('home.kpiMax')
      }
      return tooltipAdvices[this.chartsCount]
    },
    showBanner () {
      return this.availableCharts.weight || this.availableCharts.imc || this.availableCharts.uricAcid || this.availableCharts.glucose || this.availableCharts.cholesterolTotal
    },
    /* TODO estas computadas podrían calcularse en el BarChartDoubleStack directamente */
    /* en plan... último dato, labels... */
    highlightedData () {
      return this.chartDataBase.data[Object.keys(this.chartDataBase.data)[Object.keys(this.chartDataBase.data).length - 1]]
    },
    allColumnLabels () { // lavels de las columnas
      return Object.keys(this.chartDataBase.data)
    },
    allDataChart () { // valores de la gráfica
      return Object.values(this.chartDataBase.data)
    }
  },
  mounted () {
    this.fetchAllKpis()
    this.initialValues()
    if (this.getKpiConfigLoaded) {
      this.initializeKpis()
    }
    console.log(this.getKpiConfig)
    this.onResize()
    this.fetchKpisConfig()
    console.log(this.getKpiConfigLoaded)
    if (!this.showBanner && this.isDataLoaded) this.visible = false
  },
  created () {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('mouseup', this.mouseUpHandler)
    window.addEventListener('mousemove', this.mouseMoveHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('mouseup', this.mouseUpHandler)
    window.removeEventListener('mousemove', this.mouseMoveHandler)
  },
  methods: {
    ...mapActions(['fetchKpisConfig', 'addKpiConfig', 'deleteKpiConfig', 'fetchWeight', 'fetchImc', 'fetchGlucose', 'fetchCholesterolTotal', 'fetchUricAcid']),
    goToMyHealth: function () {
      this.$router.push('my-digital-health')
    },
    maxYlabels: function (data) {
      let maxValue = Math.max(...data.filter(function (o) {
        if (o !== null) return o
      }))
      maxValue = Math.ceil((Math.ceil(Math.round(maxValue) / 10) * 10) + (Math.ceil(Math.round(maxValue) / 10) * 10) * 20 / 100)
      maxValue = Math.ceil(maxValue / 10) * 10
      console.log(maxValue, 'max valuee')
      return maxValue
    },
    getTitle (title) {
      console.log('entra', title)
      const target = `digitalHealth.charts.${title}.title`
      return this.$t(target)
    },
    measurement (title) {
      const target = `digitalHealth.charts.${title}.measurement`
      return this.$t(target)
    },
    isMaxKpis () {
      console.log(Object.keys(this.showCharts).filter(key => this.showCharts[key] === true))
      return Object.keys(this.showCharts).filter(key => this.showCharts[key] === true).length >= 3
    },
    filteredDataChart: function (description) { // valores de la gráfica
      console.log(description)
      // this.fetchWeight()
      // return this.filteredDataWeight
      switch (description) {
        case 'weight':
          return this.filteredDataWeight
        case 'glucose':
          console.log('entra en GLUCOSA')
          return this.filteredDataGlucose
        case 'imc':
          return this.filteredDataImc
        case 'bloodPress':
          return this.filteredDataWeight
        case 'cholesterolTotal':
          return this.filteredDataCholesterolTotal
        case 'uricAcid':
          return this.filteredDataUricAcid
        default:
          return this.filteredDataWeight
      }
      // return this.allDataChart.slice(this.allDataChart.length - this.numberOfDataToShow)
    },
    filteredXlabelsChart: function (description) { // valores de la gráfica
      console.log(description)
      // this.fetchWeight()
      // return this.filteredDataWeight
      switch (description) {
        case 'weight':
          return this.filteredXlabelsWeight
        case 'glucose':
          return this.filteredXlabelsGlucose
        case 'imc':
          return this.filteredXlabelsImc
        case 'bloodPress':
          return this.filteredXlabelsWeight
        case 'cholesterolTotal':
          return this.filteredXlabelsCholesterolTotal
        case 'uricAcid':
          return this.filteredXlabelsUricAcid
        default:
          return this.filteredXlabelsWeight
      }
      // return this.allDataChart.slice(this.allDataChart.length - this.numberOfDataToShow)
    },
    chartSubtitle: function (description) {
      switch (description) {
        case 'weight':
          return this.outstandingDataWeight
        case 'glucose':
          return this.outstandingDataGlucose
        case 'imc':
          return this.outstandingDataImc
        case 'bloodPress':
          return this.outstandingDataWeight
        case 'cholesterolTotal':
          console.log('entra en cholesterol total')
          return this.outstandingDataCholesterolTotal
        case 'uricAcid':
          return this.outstandingDataUricAcid
        default:
          return this.outstandingDataWeight
      }
    },
    handleShowChart (event) {
      if (!this.showCharts[event] && this.isMaxKpis()) return
      this.showCharts[event] = !this.showCharts[event]
      const a = this.getKpiConfig.find(x => x.description === event)
      if (this.showCharts[event]) {
        if (!a) {
          this.isItemLoading = true
          this.addKpiConfig(event).catch(() => {
            this.showCharts[event] = !this.showCharts[event]
          })
          // this.fetchKpi(event)
        }
      } else {
        const x = this.getKpiConfig.find(t => t.description === event)
        const index = this.getKpiConfig.indexOf(x)
        if (index > -1) {
          this.getKpiConfig[index].isLoading = true
        }
        this.deleteKpiConfig(event)
        this.kpis.splice(index, 1)
      }
    },
    handlerOptionsPanel () {
      this.showOptions = !this.showOptions
    },
    disableTooltips (item) {
      return ((this.chartsCount === 2 && this.showCharts[item]) || (this.chartsCount > 2 && !this.showCharts[item])) || !this.availableCharts[item]
    },
    onResize: function () {
      this.rightMovement = 0
      this.windowSize = window.innerWidth
      const parentWidth = document.querySelector('.home__item--digital-health').clientWidth
      // const childWidth = document.querySelector('.grids-container').clientWidth
      this.maxMovement = 620 - parentWidth + 40
      // console.log(parentWidth, this.maxMovement)
    },
    mouseMoveHandler (e) {
      if (this.startMovement === true && this.windowSize < 700) {
        console.log('Moviendo', this.rightMovement)
        if (this.rightMovement < 0) {
          this.rightMovement = 0
        } else if (this.rightMovement <= this.maxMovement) {
          this.rightMovement = this.rightMovement - e.movementX
        } else {
          this.rightMovement = this.maxMovement
        }
      }
      // } else {
      //   console.log('nada')
      // }
    },
    mouseDownHandler (e) {
      // const startPosition = e.offsetX
      // console.log(startPosition)
      // let mouseMovement = e.offsetX
      // this.mouseMoveHandler(e)
      this.startMovement = true
      // console.log('mousedown', this.startMovement)
    },
    mouseUpHandler () {
      this.startMovement = false
      // console.log('mouseup', this.startMovement)
    },
    async initFunctions () {
      await Promise.all([this.getMyAppointments(), this.getCompanies()])
    },
    fetchAllKpis () {
      this.fetchWeight()
      this.fetchImc()
      this.fetchGlucose()
      this.fetchCholesterolTotal()
      this.fetchUricAcid()
    },
    fetchKpi (description) {
      switch (description) {
        case 'weight':
          this.fetchWeight()
          break
        case 'glucose':
          this.fetchGlucose()
          break
        case 'imc':
          this.fetchImc()
          break
        case 'bloodPress':
          return null
        case 'cholesterolTotal':
          return null
        case 'uricAcid':
          return null
        default:
          return null
      }
    },
    initializeKpis () {
      this.getKpiConfig.forEach(element => {
        this.showCharts[element.description] = true
        this.fetchKpi(element.description)
      })
      const a = this.getKpiConfig
      this.kpisCharts.forEach(element => {
        const found = a.find(x => x.description === element.description)
        console.log(a)
        console.log(element)
        console.log(found)
        if (found) {
          element.selected = true
        }
      })
    },
    isChecked (description) {
      console.log(this.getKpiConfig.find(x => x.description === description))
      const a = this.getKpiConfig.find(x => x.description === description)
      return !!(a)
    },
    initialValues () {
      if (this.getWeightState === 'finished') {
        this.isWeightLoaded = true
        if (this.filteredDataWeight.length !== 0 && this.outstandingDataWeight !== null && this.outstandingDataWeight !== undefined) {
          this.availableCharts.weight = true
        }
      }
      if (this.getImcState === 'finished') {
        this.isImcLoaded = true
        if (this.filteredDataImc.length !== 0 && this.outstandingDataImc !== null && this.outstandingDataImc !== undefined) {
          this.availableCharts.imc = true
        }
      }
      if (this.getGlucoseState === 'finished') {
        this.isGlucoseLoaded = true
        if (this.filteredDataGlucose.length !== 0 && this.outstandingDataGlucose !== null && this.outstandingDataGlucose !== undefined) {
          this.availableCharts.glucose = true
        }
      }
      if (this.getCholesterolTotalState === 'finished') {
        this.isCholesterolTotalLoaded = true
        if (this.filteredDataCholesterolTotal.length !== 0 && this.outstandingDataCholesterolTotal !== null && this.outstandingDataCholesterolTotal !== undefined) {
          this.availableCharts.cholesterolTotal = true
        }
      }
      if (this.getUricAcidState === 'finished') {
        this.isUricAcidLoaded = true
        if (this.filteredDataUricAcid.length !== 0 && this.outstandingDataUricAcid !== null && this.outstandingDataUricAcid !== undefined) {
          this.availableCharts.uricAcid = true
        }
      }
    }
  },
  watch: {
    isDataLoaded: {
      handler (newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          if (!this.showBanner) this.visible = false
        }
      },
      deep: true
    },
    getKpiConfigLoaded: {
      handler (newVal) {
        if (newVal) this.initializeKpis()
      },
      deep: true
    },
    getKpiConfig: {
      handler (newVal) {
        this.isItemLoading = false
        console.log('newval', newVal)
      },
      deep: true
    },
    getWeightState: {
      handler (newVal, oldVal) {
        if (newVal === 'finished') {
          this.isWeightLoaded = true
          if (this.filteredDataWeight.length !== 0 && this.outstandingDataWeight !== null && this.outstandingDataWeight !== undefined) {
            this.availableCharts.weight = true
          }
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.isWeightLoaded = false
        }
      },
      deep: true
    },
    getImcState: {
      handler (newVal, oldVal) {
        if (newVal === 'finished') {
          this.isImcLoaded = true
          if (this.filteredDataImc.length !== 0 && this.outstandingDataImc !== null && this.outstandingDataImc !== undefined) {
            this.availableCharts.imc = true
          }
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.isImcLoaded = false
        }
      },
      deep: true
    },
    getGlucoseState: {
      handler (newVal, oldVal) {
        if (newVal === 'finished') {
          this.isGlucoseLoaded = true
          if (this.filteredDataGlucose.length !== 0 && this.outstandingDataGlucose !== null && this.outstandingDataGlucose !== undefined) {
            this.availableCharts.glucose = true
          }
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.isGlucoseLoaded = false
        }
      },
      deep: true
    },
    getCholesterolTotalState: {
      handler (newVal, oldVal) {
        if (newVal === 'finished') {
          this.isCholesterolTotalLoaded = true
          if (this.filteredDataCholesterolTotal.length !== 0 && this.outstandingDataCholesterolTotal !== null && this.outstandingDataCholesterolTotal !== undefined) {
            this.availableCharts.cholesterolTotal = true
          }
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.isCholesterolTotalLoaded = false
        }
      },
      deep: true
    },
    getUricAcidState: {
      handler (newVal, oldVal) {
        if (newVal === 'finished') {
          console.log(newVal, 'URIC ACID')
          this.isUricAcidLoaded = true
          if (this.filteredDataUricAcid.length !== 0 && this.outstandingDataUricAcid !== null && this.outstandingDataUricAcid !== undefined) {
            this.availableCharts.uricAcid = true
          }
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.isUricAcidLoaded = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scope>

.grab {cursor: -webkit-grab; cursor: grab !important;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing !important;}

.home__item.home__item--main.home__item--digital-health {
  padding: 30px 80px 30px 55px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  // gap: 78px; /* TODO que sea ajustable */
  position: relative;
  overflow: hidden;
  cursor: auto;
  // display: grid;
  // grid-template-columns: 230px 200px 200px 200px;
  // grid-template-columns: 230px 660px;
  .head-container {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    width: 230px;
    height: 100%;
  }
  .grids-container {
    overflow-x: auto;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 200px 200px 200px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
  }
  .home {
      &__item {
          &__title-container {
              display: flex;
              flex-direction: row !important;
              gap: 15px;
          }
          &__title {
              margin: 0px;
              line-height: 1.2em;
              max-width: 170px;
              display: contents;
          }
          &__link-container {
              max-width: 235px !important;
          }
      }
  }
  @media screen and (max-width: 1000px) {
    // grid-template-columns: auto;
    flex-direction: column;
    padding: 40px !important;
    gap: 15px;
    .head-container {
      grid-column: 1/ -1;
      height: fit-content;
    }
    .grids-container {
      display: grid;
      grid-template-columns: 200px 200px 200px;
      gap: 10px;
      width: calc(100% + 20px);
    }
    .home {
      &__item {
          &__icon:before {
            font-size: 30px;
            display: flex;
          }
          &__title-container {
              align-items: center;
          }
          &__title {
              max-width: unset;
              font-size: 1.5rem;
          }
          &__link-container {
              display: none;
          }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 25px 0 25px 20px !important;
  }
}
.home__item.home__item--main.home__item--digital-health .grids-container {
  @media screen and (max-width: 768px) {
    margin-left: -20px;
    overflow-y: hidden;
    // margin-right: -25px;
    .chart-content {
      margin-left: 25px;
    }
    .chart-content:last-child {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        right: -50px;
        width: 70px;
        height: 100%;
      }
    }
    .glow-icon {
      position: absolute;
    }
  }
}

.new-kpi-square {
  width: 200px;
  height: 200px;
  background-color: #ffffff1a;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  gap: 10px;
  & .icon {
    border-radius: 50%;
    background-color: #ffffffbf;
    padding: 13px 12px 11px 12px;
    color: #41ABB3;
    transition: all 0.3s ease-in-out;
  }
  & .new-kpi__text{
    color: #ffffffbf;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: #ffffff1e;
    & .icon {
      background-color: #fff;
      box-shadow: 0px 1px 16px -4px #ffffff;
    }
    & .new-kpi__text{
      color: #fff;
    }
  }
}

.new-kpi-options {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffffffe6;
  height: 100%;
  width: 400px;
  transform: translate(400px);
  transition: all 0.3s ease-in-out;
  &.opened {
    transform: translate(0%);
  }
  &__head-container {
    padding: 20px 25px 25px 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    &__icon {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    &__icon:hover {
      color: #41ABB3;
    }
    &__title {
      font-size: 18px;
      text-align: center;
    }
  }
  &__toggle-container {
    display: grid;
    gap: 20px 40px;
    grid-template-columns: auto auto;
    justify-items: end;
    justify-content: center;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    transform: translate(100%);
    &__head-container {
      &__title {
        font-size: 12px;
        text-align: center;
      }
    }
    &__toggle-container {
      gap: 22px;
      & .common-review {
        font-size: 13px;
      }
    }
  }
}

.chart-content {
  position: relative;
  &:hover {
    & .hide-chart-icon{
      opacity: 1;
    }
    & .chart-banner {
      background-color: #ffffff1e;
    }
  }
  .chart-banner {
    width: 200px;
    height: 200px;
    border-radius: 6px;
    background-color: #ffffff1a;
    transition: all 0.3s ease-in-out;
  }

  .hide-chart-icon {
    opacity: 0;
    position: absolute;
    right: 7px;
    top: 7px;
    color: #ffffffbf;
    transition: all 0.3s ease-in-out;
    .icon {
      font-size: 18px;
    }
  }
}

.new-kpi-icon {
  position: absolute;
  right: 25px;
  top: 30px;
  color: #ffffffbf;
  cursor: pointer;
}

.glow-icon {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fff9;
  background-color: #ffffff00;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #ffffff26;
    cursor: pointer;
  }
}

</style>
